#logout-pane{
  background: #FFFFFF;
  border: 1px solid #8C939A;
  border-radius: 5px;
  width: 140px;
  height: 46px;
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;

  &:hover{
    background: rgba(0,0,0,0.1);;
  }
  .plain-text{
    width: max-content;
    height: 19px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #1E182A;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
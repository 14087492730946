* { padding: 0; margin: 0; }
@font-face {
  font-family: 'Work Sans';
  src: local('./Public/fonts/WorkSans-Regular.woff'), url('./Public/fonts/WorkSans-Regular.woff') format('woff');
}
html, body, #root {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  max-width: 100%;
  overscroll-behavior: none;
  cursor: default;
  background: #F7F8FC;
}
.padding15{
  padding:15px;
}
.padding30{
  padding:30px;
}
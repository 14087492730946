#active-watches-tile{
  height: fit-content;
  text-align: center;
  position: relative;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  background: #ffffff;
  width: calc(100% - 30px);
  padding: 10px;
  flex-grow: 1;
  min-width: 280px;
  max-width: calc(100% - 30px);
  box-sizing: border-box;
  margin: 8px 15px 10px;
  .title{
    text-align: left;
  }
  .container{
    width:100%;
    height: 90%;
    color:#727A82;
    scrollbar-width: none;
    overflow: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      display: none;
    }
    .empty-title{
      height: 30%;
      padding-top: 10%;
      padding-bottom: 10%;
      text-align: center;
    }
    .watch-item{
      height: 28px;
      padding: 25px 5px;
      width: 45%;
      position: relative;
      justify-content: space-evenly;
      display: inline-flex;
      .dot {
        height: 10px;
        width: 10px;
        margin-top:9px;
        border-radius: 5px;
        background: #4AD133;
      }
      .watch-icon{
        align-self: center;
      }
      .watch-name{
        padding: 0 10px;
        font-size: 18px;
        line-height: 30px;
      }
      .vertical-separator{
        height: 28px;
        width: 1px;
        align-self: center;
        border-left: 1px solid #E6E8EB;
        left: 100%;
        position: absolute;
      }
    }
    .left-0 {
      left: 0;
      float: left;
    }
    .right-0 {
      right: 0;
      float: right;
    }
  }

}
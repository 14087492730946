#main-header{
  background-color: #1E182A;
  height: 56px;
  width: 100%;
  color: #F9F7F4;
  font-size: 18px;
  position: fixed;
  z-index: 2;
  display: inline-flex;
  justify-content: space-evenly;
}
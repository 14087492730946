#dashboard-page {
  background: #F7F8FC;
  font-family: Work Sans;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .scrolling-container{
    margin-top:66px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

}
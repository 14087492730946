#swimmers-count-history-tile {
  height: 280px;
  background: #ffffff;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  width: calc(100% - 30px);
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  min-width: 280px;
  max-width: calc(100% - 30px);
  box-sizing: border-box;
  margin-top: 8px;
  margin-right: 15px;
  margin-left: 15px;

  .container {
    display: flex;
    justify-content: flex-start;
    position: relative;

    .tile-title {
      width: fit-content;
      white-space: nowrap;
      position: absolute;
      left: 2.5%;
    }
  }
}
#live-status-pane{
  display: flex;
  justify-content: space-between;
  width: auto;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  .status-container{
    height: 35px;
    background: white;
    justify-content: space-evenly;
    box-shadow: 3px 2px 8px 1px hsl(0deg 0% 39% / 20%);
    padding: 3px;
    display: inline-flex;
    align-items: center;
    width: 47%;
    border-radius: 5px;
    .icon-bg{
      width: 30px;
      height: 30px;
      background: #FCEBE3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ED7847;
      fill: #ED7847;
      border-radius: 5px;
      flex: none;
      flex-grow: 0;
      margin-left: 1px;
      border-radius: 5px;
    }
    .plain-text{
      width: 25vw;
      height: 45px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      max-font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      flex: none;
      flex-grow: 1;

      color: #727A82;
    }
    .number-text{
      margin-right: 5px;
      height: 45px;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 16px;
      display: flex;
      align-items: center;
      align-self: center;
      flex: none;
      flex-grow: 0;
      color: #1E182A;
    }
  }
}
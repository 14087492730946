
#reset-password-new-cred-page {
  background: #fff;
  display: flex;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  .top-section {
    width: 90%;
    max-height: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 100px;

    .text-pane {
      background-color: white;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .back-arrow {
        color: #1E182A;
        position: absolute;
        left: -15px;
        top: 7px;

      }

      .title-text {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        color: #1E182A;
        width: max-content;
        max-width: 100%;
      }

      .explain-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        text-align: center;
        color: #1E182A;
      }

      .password-text {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        padding-bottom: 5px;
      }
    }

    .input-container {
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 80%;

      .sub-container {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        max-width: 312px;
        height: 48px;

        .basic-input {
          &:-webkit-autofill {
            -webkit-background-clip: text !important;

          }

          position: absolute;
          box-sizing: border-box;
          width: 100%;
          height: 48px;
          background: none;
          color: #231F20;
          border: 1px solid #8C939A;
          border-radius: 5px;
          z-index: 1;
          padding: 0 10px;

          &:focus {
            outline: none;
          }

          &:focus-visible {
            outline: none;
          }
        }

        .floating-label {
          position: absolute;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          width: max-content;
          background: none;
          color: #8C939A;
          transition: 0.2s ease all;
          top: 15px;
          left: 10px;
        }

        .floating-visibility {
          position: absolute;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          width: max-content;
          background: none;
          color: #8C939A;
          right: 10px;
          top: 13px;
          z-index: 2;
          cursor: pointer;
        }

        input:focus ~ .floating-label,
        input:not(:focus):valid ~ .floating-label {
          opacity: 1;
          font-size: 14px;
          line-height: 16px;
          background: #fff;
          z-index: 2;
          padding-left: 4px;
          top: -8px;
          left: 10px;
          padding-right: 4px;
        }

        .username-error {
          position: absolute;
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          height: fit-content;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          top: 105%;
          left: 0;
          color: #D02E26;
        }
      }
    }
  }

  .bottom-section {
    height: 25%;
    gap: 50px;
    width: 90%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .bottom-label {
      display: flex;
      justify-content: space-between;
      width: 163px;
      height: 22px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      text-align: center;
      color: #727A82;
    }

    .back-button {
      text-transform: capitalize;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      max-width: 312px;
      width: 80%;
      height: 48px;
      background: #1E182A;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }

      .button-text {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #1E182A;
      }
    }
  }
}
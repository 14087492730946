#risk-level-tile {
  height: 130px;
  width: 50%;
  position: relative;
  bottom: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .graph-area{
    margin-top: -10px;
    position: relative;
    .techometer {
      padding: 0 5px 2px 2px;
      position: relative;
    }
    .arrow-small {
      position: absolute;
      top: 95%;
      height: 13px;
      left: 0px;
      transform: rotateZ(3deg) translateX(0px) translateY(0px);
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      margin-top: -2.7mm;
      margin-left: 6.2mm;
      margin-bottom: 10mm;
      width: 45px;
    }
  }
  .value-title-container {
    margin-top: 2mm;
    width: 100%;
    justify-content: center;
    display: inline-flex;
    margin-left: -10px;
    .value-title {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
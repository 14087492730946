#date-selector {
  height: 40px;
  width: auto;
  z-index: 1;
  display: inline-flex;
  justify-content: space-between;
  margin-right: 20px;
  position: relative;
  .calendar-title {
    display: inline-flex;
    align-items: center;
    color: #727A82;

    .date-value {
      padding: 1.5mm;
      font-weight: 500;
      width: max-content;
      font-style: normal;
      font-size: 14px;
      line-height: 16px;
      display: flex;
    }

    .date-icon-arrow {
      color: #727A82;
    }
  }

}

#date-selector-container{
  position: fixed;
  background: #fff;
  left: 0;
  bottom: 0;
  z-index: 3;
  box-shadow: 0 0 8px 14px rgba(125,125,125, 0.5);
  box-sizing: border-box;
  width: 100vw;
  height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;

  .date-range{
    height: max-content;
    .rdrMonths{
      .rdrMonth{
      }
    }
  }
  .bottom-container {
    height: auto;
    display: inline-flex;
    position: relative;
    bottom: 0;
    transform: translateY(50%);
    width: 100vw;
    justify-content: space-evenly;

    .button {
      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      box-sizing: border-box;
      width: 144px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: capitalize;
      text-overflow: clip;
      box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
      border-radius: 5px;
    }

    .cancel {
      background: #FFFFFF;
      border: 1px solid #E6E8EB;
      font-weight: 400;
      color: #1E182A;
    }

    .submit {
      font-weight: 500;
      color: #FFFFFF;
      background: #57A6DB;
    }
  }
}

#language-selector {
  width: 40px;
  height: 65px;
  float: right;
  padding: 0 15px 0 20px;
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

  .MuiInputBase-input {
    background: transparent !important;
  }

  .language-container {
    color: whitesmoke;
    border-radius: 50%;
    display: flex;
    width: fit-content;
    justify-content: space-between;

    img {
      max-height: 35px;
    }

    .selected-language-button {
      width: 32px;
      height: 32px;
      font-size: 16px;
      border-radius: 20px;
      line-height: 35px;
      text-align: center;
      color: #1E182A;
      font-weight: 550;
      background-color: #E9ECF7;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;

      &:hover {
        background-color: #ec9a77;
        transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      }
    }

    .language-label {
      padding: 5px;
    }
  }
}
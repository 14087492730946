#page-404 {
  margin: 0;
  padding: 0;
  overflow: hidden;


  .header-page-404 {
    font-family: 'Nunito', sans-serif;
    color: #2D3965;
    position: absolute;
    margin-top: 25%;
    padding-top: 4em;
    width: 100%;
    text-align: center;
    opacity: 0;
  }

  svg {
    position: absolute;
    height: 120%;
    left: -30%;
    width: 160%;
    bottom: -30%;
  }
}
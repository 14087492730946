#login-page {
  background: #fff;
  height: calc(var(--vh) * 100);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;

  .top-section {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .Mui-selected {
      color: #1f172a;
      border-radius: 10px 10px 0 0;
    }

    .switch-container {
      padding: 10px 20px;
      height: fit-content;
      display: flex;
      justify-content: flex-start;

      .title {
        width: fit-content;
        line-height: 30px;
        font-weight: 600;
        font-size: 1.6em;
        margin-top: 25px;
      }
    }
    .form {
      width: 100%;
      position: relative;
      justify-content: center;
      height: 240px;
      display: flex;
      flex-flow: column;


      .fp-container {
        display: inline-flex;
        justify-content: flex-end;
        padding: 10px 45px;

        .link-fp {
          font-size: 1em;
          position: relative;
          text-align: end;
        }
      }

      .paddingB {
        padding-bottom: 10px;
      }


      .mini-container {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;

        label {
          align-self: flex-start;
          margin-left: 45px;
          font-size: 1.05em;
          font-weight: 500;
        }

        .input-field {
          width: calc(100% - 110px);
          height: 45px;
          padding-left: 20px;
          font-size: 1.1em;
          font-weight: 500;
          border: 0;
          border-radius: 5px;
          display: inline-block;
          box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.15);
          text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.66);
          transition: all 200ms cubic-bezier(0.42, 0, 0.91, 0.41);
        }

        .errorState {
          border: 1px solid #ed7847;

        }
      }
    }
    .height {
      height: 220px;
    }

    .title-container {
      width: max-content;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title-text {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #1E182A;
        padding-bottom: 5px;
      }

      .sub-title-text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 135%;
        text-align: center;
        color: #1E182A;
      }
    }

    .input-container {
      display: flex;
      background-color: #9aafe2;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      height: 140px;
      padding: 20px;

      .sub-container {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        max-width: 312px;
        height: 48px;

        .basic-input {
          &:-webkit-autofill {
            -webkit-background-clip: text !important;
          }

          position: absolute;
          box-sizing: border-box;
          width: 100%;
          height: 48px;
          background: none;
          color: #231F20;
          border: 1px solid #8C939A;
          border-radius: 5px;
          z-index: 1;
          padding: 0 10px;

          &:focus {
            outline: none;
          }

          &:focus-visible {
            outline: none;
          }
        }

        .floating-label {
          position: absolute;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          width: max-content;
          background: none;
          color: #8C939A;
          transition: 0.9s ease all;
          top: -20px;
          left: 10px;
        }

        .floating-visibility {
          position: absolute;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          width: max-content;
          background: none;
          color: #8C939A;
          right: 10px;
          top: 13px;
          z-index: 2;
          cursor: pointer;
        }

        .forgot-password-text {
          position: absolute;
          right: 0px;
          top: 120%;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 135%;
          text-align: center;
          letter-spacing: 0.01em;
          text-decoration-line: underline;
          color: #1E182A;
        }

        input:focus ~ .floating-label,
        input:not(:focus):valid ~ .floating-label {
          opacity: 1;
          font-size: 14px;
          line-height: 16px;
          background: #fff;
          z-index: 2;
          padding-left: 4px;
          top: -8px;
          left: 10px;
          padding-right: 4px;
        }
      }
    }
  }

  .bottom-section {
    width: 100%;
    max-height: 45%;
    height: 45%;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .login-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 55%;
      max-width: 312px;
      height: 48px;
      background: #1E182A;
      border-radius: 5px;
      margin-bottom: 15%;

      .button-text {
        width: 100%;
        font-weight: 600;
        font-size: 18px;
        align-items: center;
        text-align: center;
        color: #FFFFFF;
        text-transform: capitalize;
      }
    }
      .disabled{
        background-color: #6c6180;
      }
    .bottom-label {
      display: inline-flex;
      width: 163px;
      height: 22px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 135%;
      text-align: center;
      color: #727A82;
      margin-bottom: 15%;
    }
  }
}
#general-live-page{
  height: 100%;
  .main-container{
    margin-top: 5px;
    height: 100%;
    overflow-y: auto;
    .last{
      margin-bottom: 70% !important;
    }
  }
}
#lifeguard-reports{
  height: 277px;
  background: #ffffff;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  width: calc(100% - 30px);
  display: flex;
  position: relative;
  padding: 10px;
  flex-grow: 1;
  min-width: 280px;
  max-width: calc(100% - 30px);
  box-sizing: border-box;
  margin: 8px 15px 10px;
  .title{
    position: absolute;
    left: 5%;
    top: 5.78%;
  }
  .left-container{
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .major-title-container{
      height: fit-content;
      width: fit-content;
      position: absolute;
      z-index: 0;

      .major-title{
        font-weight: 500;
        font-size: 40px;
        text-align: center;
      }
      .sub-major-title{
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #727A82;
      }
    }
    .clip-board{
      position: absolute;
    }
  }


  .right-container{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    .arrow-icon{
      padding: 8px;
      transform: rotateZ(180deg);
    }
    .bottom-label-lg{
      font-weight: 400;
      font-size: 14px;
      width: 112px;
      text-align: center;
      position: relative;
    }
  }
}
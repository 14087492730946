#date-and-time{
  height: 30px;
  float: left;
  padding: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;
  color: #727A82;
  width: 100%;
}
#safety-event-tile {
  height: 360px;
  background: #ffffff;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  width: calc(100% - 30px);
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1;
  min-width: 280px;
  max-width: calc(100% - 30px);
  box-sizing: border-box;
  margin: 8px 15px 10px;
  .title{
    position: absolute;
    left: 15px;
    top: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    width: max-content;
    display: flex;
    align-items: center;
    color: #1E182A;
  }

  .top-container{
    width: 100%;
    height: 65%;
    display: flex;
    .left-top-pane{
      width: 60%;
      height: 100%;
      margin-top: 20px;
      position: relative;
      .major-title-container{
        height: 27mm;
        width: 27mm;
        border-radius: 50%;
        background: #F9F7F4;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.2);
        left: 27.5px;
        top: 50%;
        transform: translateY(-50%);

        .major-title{
          font-weight: 600;
          font-size: 40px;
          text-align: center;
        }
        .sub-title{
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          color: #727A82;
        }
      }
      .circle-chart{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 158px;
        width: 158px;
      }
    }

    .right-top-pane{
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .arrow-icon{
        padding: 8px;
        rotate: 180deg;
      }

      .safety-right-label{
        width: 112px;
        height: 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #1E182A;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }

  .bottom-container{
    width: 100%;
    height: 35%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .item-row{
      display: inline-flex;
      justify-content: space-between;
      width: 90%;
      .pre{
        display: inline-flex;
        align-items: center;
        .bullet{
          width: 12px;
          height: 12px;
          border-radius: 2px;
          margin: 8px;
        }
        .navy-blue{
          background: #1F628F;
        }
        .blue{
          background: #57A6DB;
        }
        .light-blue{
          background: #C4E0F2;
        }
      }
      .item-title{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #1E182A;
      }
    }
    .item-value{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      text-align: right;
      color: #1E182A;
      margin-right: 8px;
    }
  }
}
#slide-menu{
  flex-direction: column;
  height: 100vh;
  .header{
    background: #1E182A;
    overflow-x: hidden;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    border-bottom: 1px solid #E6E8EB;
    .company-icon{
      max-width:150px;
      max-height: 45px;
      float:left;
    }
    .close-button {
      color: #fff;
    }
  }
  .row{
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #E6E8EB;
  }
  .logout{
    bottom: 38px;
    left: 24px;
    position: absolute;
  }
}
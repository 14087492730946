#history-page {
  height: 100%;
  z-index: 0;
  .history-title {
    display: inline-flex;
    padding-top: 15px;
    padding-left: 15px;
    width: calc(100% - 15px);
    justify-content: space-between;
    align-items: center;
    .left-side{
      display: inline-flex;
      .title-text {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #1E182A;
      }

      .title-icon {
        width: 22px;
      }
    }

  }
  .main-container{
    margin-top: 5px;
    overflow-y: auto;
    height: 100%;
    .last{
      margin-bottom: 70% !important;
    }
  }
}
#company-name{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 154px;
    height: 32px;
    //background: #463E53;
    border-radius: 60px;
    .user-title{
        text-transform: capitalize;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #F9F7F4;
    }
  }
#live-page{
  height: 100%;
  .bottom-placeholder{
    height: 1mm;
  }
  .main-container{
    margin-top: 5px;
    overflow-y: auto;
    height: 100%;
    .last{
      margin-bottom: 70% !important;
    }
  }
}
#swipe-tabs {
  position: relative;
  width: 100vw;
  top: 56px;
  height: calc(100vh - 56px);
  .swipe-tabs-header {
    box-shadow: 0px 2px 14px rgba(31, 98, 143, 0.1);
    height: 40px;
    width: 100vw;
    position: relative;
    overflow-y: hidden;
    .items {
      position: absolute;
      display: inline-flex;
      width: 100%;
      justify-content: center;
      height: 40px;
      background: radial-gradient(circle farthest-side, rgba(255, 255, 255, 1) 0, rgba(255, 255, 255, 1) 34%, rgba(255, 255, 255, 1) 38%, rgba(255, 255, 255, 1) 84%, rgba(255, 255, 255, 1) 86%, rgba(244, 244, 244, 1) 89%, rgba(219, 219, 219, 1) 93%, rgba(196, 196, 196, 1) 97%, rgba(175, 175, 175, 1) 100%);

      .swiper-slide {
        color: #869099;
        font-weight: 500;
        display: inline-flex;
        height: 19px;
        text-align: center;
        justify-content: center;
        align-self: center;
        font-style: normal;
        font-size: 11px;
        line-height: 19px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        .header-pool-name{
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: calc(100% / 2);
        }
        &-active {
          min-width: max-content !important;
          text-align: center !important;
          .header-pool-name{
            text-align: center !important;
            text-overflow: unset !important;
            white-space: unset !important;
            overflow: unset !important;
            color: #1E182A !important;
            font-weight: 600 !important;
            min-width: max-content !important;
            font-size: 16px !important;
          }
        }
      }
      .dot {
        width: 5px;
        height: 5px;
        border-radius: 6px;
        align-self: center;
        margin-right: 3px;
      }
    }
  }
  .view-container{
    display: inline-flex;
    justify-content: space-between;
    margin-left: 15px;
    margin-top: 10px;
    height: 40px;
    width: calc(100% - 10px);
    .view-title{
      display: inline-flex;
      align-self: center;
      align-items: center;
      height: 25px;
      width: max-content;
      justify-content: space-between;
      .view-text{
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #1E182A;
        padding-left: 5px;
      }
    }
  }
  .main-pane {
    height: calc(100vh - 132px);
    width: 100vw;
    .pages {
      height: 100%;
      width: 100%;
    }
    .change-view-button {
      background: #1E182A;
      border-radius: 40px;
      width: 250px;
      height: 48px;
      position: fixed;
      z-index: 1;
      bottom: 10%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: space-around;
      .divider {
        left: 50%;
        align-self: center;
        position: absolute;
        width: 0px;
        height: 29px;
        border: 1px solid #727A82;
      }
      .view {
        width: 125px;
        height: 48px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        letter-spacing: 0.3px;
        color: #869099;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
      }
      .active {
        color: #fff;
      }
    }
  }
}

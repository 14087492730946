#pool-diagram-tile{
  background: #ffffff;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  position: relative;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  border-radius: 5px;
  width: calc(100% - 30px);
  height: 600px;
  padding: 10px;
  flex-grow: 1;
  min-width: 280px;
  max-width: calc(100% - 30px);
  box-sizing: border-box;
  margin: 8px 15px 150px;
  z-index: 3;

  .inner-box-pd{
    display: flex;
    padding: 0;
    align-items: center;
    width: 100vw;
    justify-content: center;
    min-width: 625px;
  }
  .pool-image-container{
    height: fit-content;
    width: fit-content;
    position: absolute;
    display: flex;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -45%) rotate(90deg);

    justify-content: center;
    .img-pool{
      height:250px;
    }
    .swimmers-layout{
      width:100%;
      height: 100%;
      top:0;
      position: absolute;
      overflow: hidden;
      .dot{
        background-color: #57A6DB;
        border-radius: 50%;
        height: 8px;
        width: 8px;
        position: absolute;
      }
    }
  }
  .pool-tile-title{
    width: fit-content;
    white-space: nowrap;
    position: relative;
  }
}
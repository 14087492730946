#pool-general-tile{
  background: #ffffff;
  width: calc(100% - 30px);
  height: fit-content;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  flex-grow: 1;
  min-width: 280px;
  max-width: calc(100% - 30px);
  box-sizing: border-box;
  box-shadow: 3px 2px 8px 1px rgba(100, 100, 100, 0.2);
  margin-top: 8px;
  margin-right: 15px;
  margin-left: 15px;
  .title {
    width: max-content;
  }
  .general{
    text-decoration: underline #1E182A 1px;
    //text-decoration: underline #8C939A 1px;
  }
  .main-container{
    width: 100%;
    overflow-x: hidden;
    min-height: 110px;
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    .vertical-separator{
      height: 90px;
      z-index: 1;
      border-left: 1px solid #E6E8EB;
      align-self: center;
      left: 50%;
    }
    .right-label-container{
      width: 50%;
      display: flex;
      justify-content: center;
      height: fit-content;
      align-self: center;
      align-items: center;
      position: relative;
      .circle-chart{
        height: 114px;
        width: 114px;
      }
    }
    .general-label-container {
      align-self: center;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      position: absolute;
      padding:5px;
      .risk-sub-title {
        height: 14px;
        font-weight: 500;
        font-size: 11px;
        align-items: center;
        text-align: center;
        color: #727A82;
      }
      .risk-main-title {
        font-weight: 500;
        font-size: 40px;
        color: #1E182A;
        text-align: center;
      }
    }
  }
}
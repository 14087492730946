#menu-selector{
  position: relative;
  align-self: center;
  margin-left: auto;
  margin-right: 25px;
  height: 30px;
  .user-container {
    position: relative;
    .burger-menu{
      border-radius: 50%;
      text-align: center;
      align-self: center;
      transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1) 10ms;
      width:30px;
      height: 30px;
    }
  }


}
